import React, {useEffect, useRef }from 'react';
import {BigPlayButton, Player, ControlBar} from "video-react";


const ReactVideo360 = ({ vURL, poster }) => {

  const refPlayer = useRef();
  const handleVideoEnd = (player) => {
    document.getElementsByClassName('video-react-button')[0].classList.remove('big-play-button-hide');
  }

  const handlePropChange = () => {
    refPlayer.current.load();
  }

  useEffect(() => {
    handlePropChange();
  }, [vURL]);

  return(
      <div className={'three60-container'}>
        <div id="bcoveContainer" className={'nextlock'} >
      <Player
          ref={refPlayer}
          playsInline
          poster={poster}
          src={vURL}
          onEnded={(event) => handleVideoEnd(event.target)}
      >
        <BigPlayButton position={'center'} />
        <ControlBar disableCompletely />
      </Player>
        </div>
        <div className="three60" />
      </div>

  )
}
export default ReactVideo360;